import React, {Component} from "react"
import {StaticQuery, graphql} from "gatsby"
import {Container} from "reactstrap"
import styled from "styled-components"
import HeadingSpecial from "../../shared/HeadingSpecial"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {media} from "utils/Media"

const Relative = styled.div`
    position: relative;
    height: 100%;
    
    &.flex {
        @media ${media.sm} {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
    }   
`

export default class StoryBg extends Component {
    renderItems(data) {
        return (
            <Relative className="mb-4">
                <GatsbyImage placeholder="none" image={getImage(data.desktopImage)} alt="" className="img-fluid d-none d-lg-block"/>
                <GatsbyImage placeholder="none" image={getImage(data.tabletImage)} alt="" className="img-fluid d-none d-sm-block d-lg-none"/>
                <GatsbyImage placeholder="none" image={getImage(data.mobileImage)} alt="" className="img-fluid d-sm-none"/>
            </Relative>
        );
    }

    render() {
        return (
            <StaticQuery query={query} render={(props)=>this.renderItems(props)} />
        )
    }
}


const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "Story/header-story-576.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 575, quality: 100)
      }
    }
    tabletImage: file(relativePath: { eq: "Story/header-story-991.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 991, quality: 100)
      }
    }
    desktopImage: file(relativePath: { eq: "Story/header-story-1920.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100)
      }
    }
  }
`