import React from 'react'
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {Row, Col, Container} from 'reactstrap'
import {media} from "utils/Media"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import HeadingSpecial from "../../shared/HeadingSpecial";

const StoryContent = styled.div`
    position: relative;
    text-align: center;
    
    .narrow {
        max-width: 740px;
        margin: 0 auto;
    }
    
     h2 {
        font-size: 24px;
        
        @media ${media.sm} {
            font-size: 40px;
        }
        
        @media ${media.lg} {
            font-size: 40px;
        }
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                elsaAnnaImgMd: file(relativePath: { eq: "Story/adapt-story-pic2-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                elsaAnnaImg: file(relativePath: { eq: "Story/adapt-story-pic2-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 740, quality: 100)
                    }
                }
                villageMd: file(relativePath: { eq: "Story/adapt-story-pic3-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                village: file(relativePath: { eq: "Story/adapt-story-pic3-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 740, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <StoryContent>
                <Container fluid={true} className="no-padding-xs">
                            <div className="narrow">
                                <HeadingSpecial lines={false} headingTag="h2"
                                                className="py-4"
                                                title="WHERE"/>

                                <h4 className="font--regular mb-4">Arendelle, a mythical kingdom inspired by Norway</h4>

                                <HeadingSpecial lines={false} headingTag="h2"
                                                className="py-4"
                                                title="WHEN"/>
                                <h4 className="font--regular mb-4">A fictional time in the past, with costume design inspired by the 1840s</h4>
                            </div>
                    <Row className="my-5 py-5">
                        <Col xs={12} xl={6} className="my-auto">
                            <div className="narrow">
                                <HeadingSpecial lines={false} headingTag="h2"
                                                className="py-4"
                                                title="WHAT"/>

                                <h4 className="font--regular mb-4">
                                    Young sisters Elsa and Anna thrive amid warmth and love in Arendelle. One night while playing, Elsa accidentally strikes Anna with her magic. The Hidden Folk shaman Pabbie draws the magic – and memories of it – out of Anna’s head. Now frightened of her own power, Elsa isolates herself, and the sisters grow up apart.
                                </h4>
                                <h4 className="font--regular mb-4">
                                    Years later, Elsa is crowned Queen of Arendelle. When Anna and the visiting Prince Hans suddenly become engaged, Elsa panics and unleashes a blast of ice. The Duke of Weselton calls her a monster and Elsa flees, unwittingly initiating an eternal winter in her wake. Anna pursues her sister, leaving Hans in charge. Kristoff the mountain man and his reindeer Sven meet Anna on her journey and agree to help her. Along the way, they discover Olaf, the sisters’ magical childhood snowman. Now feeling safe on the North Mountain, Elsa lets her powerful magic go and creates a spectacular palace of ice.
                                </h4>
                            </div>

                        </Col>
                        <Col xs={12} xl={6} className="my-auto">
                            <div className="narrow">
                            <GatsbyImage placeholder="none" image={getImage(data.elsaAnnaImgMd)} alt="" title=""
                                 className="img-fluid d-lg-none"/>
                            <GatsbyImage placeholder="none" image={getImage(data.elsaAnnaImg)} alt="" title=""
                                 className="img-fluid d-none d-lg-block"/>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 mb-xl-5">
                        <Col xs={12} xl={6} className="my-auto order-2 order-xl-1">
                            <div className="narrow">
                            <GatsbyImage placeholder="none" image={getImage(data.villageMd)} alt="" title=""
                                 className="img-fluid d-lg-none"/>
                            <GatsbyImage placeholder="none" image={getImage(data.village)} alt="" title=""
                                 className="img-fluid d-none d-lg-block"/>
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="my-auto order-1 order-xl-2 pb-4 pb-xl-0">
                            <div className="narrow">
                                <h4 className="font--regular mb-4">With supplies from Wandering Oaken’s Trading Post, Anna reaches Elsa who refuses to return to Arendelle and accidentally strikes Anna in the heart with her magic. Kristoff takes Anna to the Hidden Folk, but Pabbie cannot draw this magic out; only an act of true love can heal her.</h4>

                                <h4 className="font--regular mb-4">Refusing to be a monster, Elsa surrenders to Hans. Back at the castle, Hans reveals his villainous plan and locks Anna in the library to die, but Olaf sneaks in and frees her. Elsa denies Hans’s charge of treason and breaks free.</h4>

                                <h4 className="font--regular mb-4">Kristoff and Anna seek each other amid the blizzard while Hans hunts Elsa. Seeing her sister in danger, Anna blocks Hans’s sword as she freezes solid. This act of true love heals Anna and releases Elsa from fear. Queen Elsa brings summer back to Arendelle, which rejoices and embraces her, magic and all.</h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StoryContent>
        )}
    />
)

export default Query;