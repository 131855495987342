import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "components/Layout/Layout"
import MoreTopics from "../../../components/Education/MoreTopics"
import styled from "styled-components";
import HeadingSpecial from "../../../components/shared/HeadingSpecial";
import SnowFlakes from "../../../components/shared/SnowFlakes";
import StoryContent from "../../../components/Education/Story/StoryContent";
import StoryBg from "../../../components/Education/Story/StoryBg";

const pageSlug = "story"
const pageTitle = "Story"
const categorySlug = "adaptation"
const categoryTitle = "Adaptation"

const Content = styled.div`
    position: relative;
    
    text-align: center;
    
    .narrow {
        max-width: 740px;
    }
`

class TheSnowQueen extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: pageSlug,
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="beyond-the-story"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                    <Content className="enrichment">
                        <HeadingSpecial lines={false} headingTag="h1"
                                        className="py-4 py-md-5 font--bigger"
                                        title={pageTitle}/>
                        <StoryBg/>
                        <StoryContent/>
                    </Content>
                    <div className="py-5">&nbsp;</div>
                    <MoreTopics adaptation design/>
                </SnowFlakes>
            </Layout>
        )
    }
}

export default TheSnowQueen